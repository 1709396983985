import { combineReducers, configureStore } from "@reduxjs/toolkit";

 //import  {combineReducers} from 'redux'
import localStorage from "redux-persist/es/storage";
import { authSlice, clearRedux, resetProfile } from "./auth/slice";
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  persistReducer,
  persistStore,
} from "redux-persist";
import { useDispatch, useSelector, TypedUseSelectorHook } from "react-redux";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { metaSlice } from "./meta/slice";
import { usersSlice } from "./users/slice";
import { staffSlice } from "./staff/slice";
import { appointmentSlice } from "./appointment/slice";
import { logout } from "./auth/action";
import { reportSlice } from "./report/slice";
import { attendenceSlice } from "./attendence/slice";
import { multiStoreSlice } from "./multi-store/slice";
import customerSlice from './customer/slice';
import invoiceSlice from './invoice/slice';
import userSlice  from './user/slice';
import snackbarSlice  from './snackbar/slice';
import calendarSlice  from './calendar/slice';
import catalogueSlice  from './catalogue/slice';
import authenticationSlice from './authentication/slice'
import employeeSlice  from './employees/slice';
import { enableMapSet } from 'immer';
const persistConfig = {
  key: "root",
  storage: localStorage,
  // blacklist: ["loader"],
  whitelist: [
    "auth",
    "users",
    "service",
    "staff",
    "appointment",
    "meta",
    "report",
    "customer",
    "user",
    "snackbar",
    "invoice",
    "attendence",
    "calendar",
    "catalogue",
    "authentication",
    "employees"
  ],
  // Other configuration options can be added here
};
enableMapSet();
const rootReducer = combineReducers({
  auth: authSlice.reducer,
  meta: metaSlice.reducer,
  users: usersSlice.reducer,
  staff: staffSlice.reducer,
  appointment: appointmentSlice.reducer,
  report: reportSlice.reducer,
  attendence: attendenceSlice.reducer,
  multiStore: multiStoreSlice.reducer,
  customer:customerSlice,
  user:userSlice,
  snackbar:snackbarSlice,
  invoice:invoiceSlice,
  calendar:calendarSlice,
  catalogue:catalogueSlice,
  employees:employeeSlice,
  authentication:authenticationSlice,
});

const appReducer = (state: any, action: any) => {
  if (
    action.type === resetProfile.type ||
    action.type === logout.fulfilled.type ||
    action.type === clearRedux.type
  ) {
    state = undefined; // Reset state to initial state on logout
  }
  return rootReducer(state, action);
};
// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, appReducer);

// Create the Redux store
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false, immutableCheck: false })
});

// Create the Redux persistor
export const persistor = persistStore(store);
export const dispatch = store.dispatch;
// Export types
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;



// Create a hook to use with useSelector for type inference
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
